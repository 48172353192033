import { Form, Flex, Modal, Typography, Button, Checkbox } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { CloseOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import InviteConnections from './InviteConnections';
import {
  useGetOrganizationDetailByCodeHandler,
  useGetOrganizationFollowers,
  useInviteMemberOrganization,
} from '../../../hooks/queries/OrganizationQueries';
import { useParams } from 'react-router-dom';
const OrganizationMemberModal = ({
  setInviteMemberModal,
  inviteMemberModal,
  connectionDetails,
}) => {
  const { theme } = useContext(UtilityContext);
  const { code } = useParams();
  const { data: organization } = useGetOrganizationDetailByCodeHandler(code);

  const { data: followers } = useGetOrganizationFollowers(organization?.id);
  const [allCheckedList, setAllCheckedList] = useState([]);
  const [checked, setChecked] = useState([]);
  const filteredConnectionDetails = connectionDetails?.filter(
    (connection) => !followers?.includes(connection.id),
  );

  const checkAll = filteredConnectionDetails?.length === checked?.length;
  const intermediate = checked.length > 0 && checked.length < filteredConnectionDetails.length;
  const onCheckAllChange = (e) => {
    let newfilteredConnectionDetails;
    if (e.target.checked) {
      newfilteredConnectionDetails = filteredConnectionDetails.map((connection) => connection.id);
    } else {
      newfilteredConnectionDetails = [];
    }

    setAllCheckedList(newfilteredConnectionDetails);
    setChecked(newfilteredConnectionDetails);
  };

  const { openNotification } = useContext(UtilityContext);

  const translator = useIntl();
  const { mutateAsync: inviteMemberOrganization } = useInviteMemberOrganization(organization?.id);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!inviteMemberModal) {
      setAllCheckedList([]);
      setChecked([]);
    }
  }, [inviteMemberModal]);

  const sendInvitation = () => {
    setIsLoading(true);
    inviteMemberOrganization(checked)
      .then((response) => {
        if (response.status === 202) {
          openNotification('inviteSend', 'success');
        } else {
          openNotification('inviteSend', 'error');
        }
      })
      .finally(() => {
        setIsLoading(false);
        setInviteMemberModal(false);
      });
  };

  return (
    <Modal
      styles={{
        content: {
          background: theme ? '#2A2A2A' : 'white',
        },
        header: { background: theme ? '#2A2A2A' : 'white' },
      }}
      closeIcon={
        <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
      }
      title={translator.formatMessage({ id: 'sendInciteToYourFriends' })}
      onCancel={() => setInviteMemberModal(false)}
      open={inviteMemberModal}
      destroyOnClose={true}
      centered={true}
      footer={null}
    >
      <Flex className='flex-col overflow-auto gap-y-2'>
        <Flex justify='end' gap={4} className='mb-2'>
          <FormattedMessage id='selectAll' />
          <Checkbox intermediate={intermediate} checked={checkAll} onChange={onCheckAllChange} />
        </Flex>
        <InviteConnections
          setChecked={setChecked}
          checked={checked}
          allChecked={allCheckedList}
          allCheckedList={allCheckedList}
          connectionDetails={filteredConnectionDetails}
        />
        <Button
          className='dark:text-dark-mode-text-color dark:disabled:text-dark-mode-disabled-text-color dark:disabled:border-dark-mode-disabled-border-color dark:border-dark-mode-primary-color'
          disabled={checked.length > 0 ? false : true}
          loading={isLoading}
          onClick={sendInvitation}
          htmlType='submit'
        >
          <FormattedMessage id='send' />
        </Button>
      </Flex>
    </Modal>
  );
};

export default OrganizationMemberModal;
