import { Button, Card, Col, Descriptions, Modal, Row, Typography, Flex } from 'antd';
import styles from '../../style/profile.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  FacebookOutlined,
  FlagOutlined,
  InstagramOutlined,
  PhoneOutlined,
  SettingTwoTone,
  UserOutlined,
  XOutlined,
  YoutubeOutlined,
  CloseOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Twitch } from '../../helpers/Icons';
import MiniGallery from '../galleries/MiniGallery';
import { useContext, useState } from 'react';
import PersonalInformationForm from '../form/user/PersonalInformationForm';
import FavouriteGamesForm from '../form/game/FavouriteGamesForm';
import {
  useMutatePersonalInformation,
  usePersonalInformation,
} from '../../hooks/queries/UserQueries';
import { countries } from '../../helpers/HelperJSON';
import variables from '../../style/_variables.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import SteamLibrary from '../integrations/steam/SteamLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKickstarterK } from '@fortawesome/free-brands-svg-icons';
import { useGetUserSteamLibrary } from '../../hooks/queries/IntegrationQueries';
import GameCard from '../cards/GameCard';
import SteamAchievements from '../integrations/steam/SteamAchievements';
import { DrawerProviderContext } from '../../providers/DrawerProvider';
import { UtilityContext } from '../../providers/UtilityProvider';
import { AuthContext } from '../../providers/AuthProvider';

const { Text } = Typography;

const ProfileAboutSection = ({ isOwner, profileDetails, changeProfileTab, showMoreSteam }) => {
  const { data: userPageInformation } = usePersonalInformation(profileDetails?.id);
  const { mutate } = useMutatePersonalInformation(profileDetails?.id, profileDetails?.username);
  const { data: userSteamLibrary } = useGetUserSteamLibrary(profileDetails?.id);
  const { width } = useWindowSize();
  const { openDrawer } = useContext(DrawerProviderContext);
  const { theme } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const { focusedPrimaryColor } = variables;
  const [personalInformationModal, setPersonalInformationModal] = useState(false);

  const [favouriteGamesModal, setFavouriteGamesModal] = useState(false);
  const location = window.location.pathname;
  const divided = location.split('/');

  const pathname = divided[2];
  const translator = useIntl();

  const callBackAndFetch = (modalParameter) => {
    if (modalParameter === 'pi') {
      setPersonalInformationModal(!personalInformationModal);
      mutate(profileDetails?.id);
    }
    if (modalParameter === 'fg') {
      setFavouriteGamesModal(!favouriteGamesModal);
    }
  };

  const modalHandler = (modalParameter) => {
    if (modalParameter === 'pi') {
      setPersonalInformationModal(!personalInformationModal);
    }
    if (modalParameter === 'fg') {
      setFavouriteGamesModal(!favouriteGamesModal);
    }
  };

  const achievementDrawerHandler = (steamApp) => {
    openDrawer({
      title: translator.formatMessage({ id: 'achievements' }),
      content: (
        <SteamAchievements
          userId={profileDetails?.id}
          isOwner={isOwner}
          image={steamApp.header_image}
          id={steamApp.app_id}
        />
      ),
      drawerProps: {},
      footer: null,
    });
  };

  return (
    <Row gutter={[16, 16]} className='sticky top-[-230px]'>
      <Col span={24}>
        <Card
          bordered={false}
          title={
            <h6 className='font-semibold m-0'>
              <FormattedMessage id='personalInformation' />
            </h6>
          }
          className={`${styles['antdCard']} header-solid h-full card-profile-information`}
          extra={
            isOwner && (
              <Button
                onClick={() => modalHandler('pi')}
                icon={<SettingTwoTone twoToneColor={focusedPrimaryColor} />}
                type='link'
              />
            )
          }
          styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
        >
          <Text className='d-block text-dark dark:text-[#9C9C9C] mb-4'>
            {userPageInformation?.description}
          </Text>
          <hr className='my-25' />
          <Descriptions
            className='mt-4'
            title={<Text>{translator.formatMessage({ id: 'about' })}</Text>}
          >
            <Descriptions.Item
              className={styles['descriptionLabel']}
              label={<UserOutlined className='dark:text-dark-mode-primary-color text-xl' />}
              span={4}
            >
              <Text>
                {profileDetails?.first_name} {profileDetails?.last_name}
              </Text>
            </Descriptions.Item>
            {userPageInformation?.phoneNumber?.length > 0 && (
              <Descriptions.Item
                className={styles['descriptionLabel']}
                label={<PhoneOutlined className='dark:text-dark-mode-primary-color text-xl' />}
                span={3}
              >
                <Text>{userPageInformation?.phoneNumber}</Text>
              </Descriptions.Item>
            )}
            {userPageInformation?.country?.length > 0 && (
              <Descriptions.Item
                className={styles['descriptionLabel']}
                label={<FlagOutlined className='dark:text-dark-mode-primary-color text-xl' />}
                span={3}
              >
                <Text>
                  {
                    countries.find((country) => country.value === userPageInformation?.country)
                      .label
                  }
                </Text>
              </Descriptions.Item>
            )}

            <Descriptions.Item className='social-description' span={3}>
              {userPageInformation?.twitter?.length > 0 && (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href={
                    userPageInformation?.twitter.includes('https://') ||
                    userPageInformation?.twitter.includes('http://')
                      ? userPageInformation?.twitter
                      : `https://${userPageInformation?.twitter}`
                  }
                  className='px-5'
                >
                  <XOutlined className='text-black dark:text-white text-2xl' />
                </a>
              )}
              {userPageInformation?.facebook?.length > 0 && (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href={
                    userPageInformation?.facebook.includes('https://') ||
                    userPageInformation?.facebook.includes('http://')
                      ? userPageInformation?.facebook
                      : `https://${userPageInformation?.facebook}`
                  }
                  className='px-5'
                >
                  <FacebookOutlined className='text-[#344e86] dark:text-[#4795FF] text-2xl' />
                </a>
              )}
              {userPageInformation?.instagram?.length > 0 && (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href={
                    userPageInformation?.instagram.includes('https://') ||
                    userPageInformation?.instagram.includes('http://')
                      ? userPageInformation?.instagram
                      : `https://${userPageInformation?.instagram}`
                  }
                  className='px-5'
                >
                  <InstagramOutlined className='text-[#e1306c] dark:text-[#ff4d84] text-2xl' />
                </a>
              )}
              {userPageInformation?.youtube?.length > 0 && (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href={
                    userPageInformation?.youtube.includes('https://') ||
                    userPageInformation?.youtube.includes('http://')
                      ? userPageInformation?.youtube
                      : `https://${userPageInformation?.youtube}`
                  }
                  className='px-5'
                >
                  <YoutubeOutlined className='text-[#e1306c] dark:text-[#e1306c] text-2xl' />
                </a>
              )}
              {userPageInformation?.twitch?.length > 0 && (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href={
                    userPageInformation?.twitch.includes('https://') ||
                    userPageInformation?.twitch.includes('http://')
                      ? userPageInformation?.twitch
                      : `https://${userPageInformation?.twitch}`
                  }
                  className='px-5'
                >
                  <Twitch viewBox='10 -8 10 40' />
                </a>
              )}
              {userPageInformation?.kick?.length > 0 && (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href={
                    userPageInformation?.kick.includes('https://') ||
                    userPageInformation?.kick.includes('http://')
                      ? userPageInformation?.kick
                      : `https://${userPageInformation?.kick}`
                  }
                  className='px-5'
                >
                  <FontAwesomeIcon
                    className={styles['kickIcon']}
                    icon={faKickstarterK}
                    style={{
                      color: '#53fc18',
                    }}
                  />
                </a>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
      <Col span={24}>
        {width > 760 && userSteamLibrary?.length > 0 ? (
          <Card
            bordered={false}
            title={
              <Flex justify='space-between'>
                <FormattedMessage id='steamLibrary' />
                <>{isOwner && <SteamLibrary profileDetails={profileDetails} />}</>
              </Flex>
            }
            className={`${styles['antdCard']} header-solid h-full`}
            styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
          >
            <>
              {user?.steam_id?.length === 0 ? (
                <Text>
                  <FormattedMessage id='noSteamIntegrated' />
                </Text>
              ) : userSteamLibrary?.length === 0 ? (
                <Text className='text-sm mb-2 block'>
                  <WarningTwoTone className='mr-1' twoToneColor='red' />
                  <FormattedMessage id='steamIntegrationPublicWarn' />
                </Text>
              ) : (
                <Row gutter={[16, 16]}>
                  {userSteamLibrary?.slice(0, 3).map(({ steamapp, time_played }, index) => (
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} key={index}>
                      <GameCard
                        genres={steamapp?.steamappgenres}
                        gameTime={time_played}
                        url={steamapp?.header_image}
                        showAchievements={() => achievementDrawerHandler(steamapp)}
                      />
                    </Col>
                  ))}
                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <GameCard showMore={showMoreSteam} more={true} />
                  </Col>
                </Row>
              )}
            </>
          </Card>
        ) : (
          pathname === user?.username && (
            <Card
              bordered={false}
              title={
                <Flex justify='space-between'>
                  <FormattedMessage id='steamLibrary' />
                  <>{isOwner && <SteamLibrary profileDetails={profileDetails} />}</>
                </Flex>
              }
              className={`${styles['antdCard']} header-solid h-full`}
              styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
            >
              <>
                {user?.steam_id?.length === 0 ? (
                  <Text>
                    <FormattedMessage id='noSteamIntegrated' />
                  </Text>
                ) : userSteamLibrary?.length === 0 ? (
                  <Text className='text-sm mb-2 block'>
                    <WarningTwoTone className='mr-1' twoToneColor='red' />
                    <FormattedMessage id='steamIntegrationPublicWarn' />
                  </Text>
                ) : (
                  <Row gutter={[16, 16]}>
                    {userSteamLibrary?.slice(0, 3).map(({ steamapp, time_played }, index) => (
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} key={index}>
                        <GameCard
                          genres={steamapp?.steamappgenres}
                          gameTime={time_played}
                          url={steamapp?.header_image}
                          showAchievements={() => achievementDrawerHandler(steamapp)}
                        />
                      </Col>
                    ))}
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                      <GameCard showMore={showMoreSteam} more={true} />
                    </Col>
                  </Row>
                )}
              </>
            </Card>
          )
        )}
      </Col>
      <Col span={24} className='lg:mb-24 md:mb-12 sm:mb-4'>
        {width > 760 ? (
          <Card
            bordered={false}
            title={
              <div className={styles['galleryHeader']}>
                <h6 className='font-semibold m-0'>
                  <FormattedMessage id='gallery' />
                </h6>
                <a className='dark:text-white' onClick={() => changeProfileTab('gallery')}>
                  <FormattedMessage id='allPhotos' />
                </a>
              </div>
            }
            className={`${styles['antdCard']} header-solid h-full`}
            styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
          >
            <MiniGallery userId={profileDetails?.id} />
          </Card>
        ) : null}
      </Col>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'personalInformation' })}
        footer={null}
        onCancel={() => modalHandler('pi')}
        open={personalInformationModal}
        destroyOnClose={true}
        centered={true}
      >
        <PersonalInformationForm
          callBackAndFetch={callBackAndFetch}
          userPageInformation={{
            ...userPageInformation,
            firstName: profileDetails?.first_name,
            lastName: profileDetails?.last_name,
            username: profileDetails?.username,
          }}
          modalHandler={() => modalHandler('pi')}
        />
      </Modal>
      <Modal
        title={translator.formatMessage({ id: 'favouriteGames' })}
        okText={translator.formatMessage({ id: 'ok' })}
        onCancel={() => modalHandler('fg')}
        cancelText={translator.formatMessage({ id: 'cancel' })}
        cancelButtonProps={{ icon: <CloseCircleFilled style={{ color: '#D61C1C' }} /> }}
        okButtonProps={{ icon: <CheckCircleFilled style={{ color: '#fff' }} /> }}
        open={favouriteGamesModal}
        destroyOnClose={true}
        centered={true}
        width='800px'
      >
        <FavouriteGamesForm />
      </Modal>
    </Row>
  );
};

export default ProfileAboutSection;
