import { Button, Col, Row, Form, Image, Typography, Flex } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from '../../../style/postCreate.module.scss';
import FileUploader from '../../../helpers/utils/FileUploader';
import React, { useContext, useState, useEffect } from 'react';
import { FileImageTwoTone, SmileOutlined, GifOutlined } from '@ant-design/icons';
import PrimaryCard from '../../cards/PrimaryCard';
import variables from '../../../style/_variables.module.scss';
import { uploadFile } from '../../../requests/UtilityManager';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import TipTap from '../../editor/TipTap';
import { usePostFeed } from '../../../hooks/queries/FeedQueries';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';
import PostCard from '../../post/PostCard';
import OutsideClickDetector from '../../hoc/OutsideClickDetector';
import useFileUploader from '../../../hooks/useFileUploader';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const CreatePostForm = ({ postDetail }) => {
  const { openNotification } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState(false);
  const [gifPickerDisplay, setGifPickerDisplay] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  const [editorString, setEditorString] = useState('');
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const { handleFileUpload } = useFileUploader();

  const { mutateAsync: postFeed } = usePostFeed();

  const { focusedPrimaryColor } = variables;

  let [postForm] = Form.useForm();

  useEffect(() => {
    // TODO: fileArray dosya yüklendiğinde array olmaktan çıkıyor ve length undefined kalıyor.
    if (
      (editorString &&
        editorString.length > 0 &&
        editorString.replace(/<(?!img\b|iframe\b)[^>]*>|\s+/g, '') !== '') ||
      (fileArray && fileArray.length != 0)
    ) {
      setCreateButtonDisabled(false);
    } else {
      setCreateButtonDisabled(true);
    }
  }, [editorString, fileArray]);

  const createPostHandler = async (formValues) => {
    setPostLoading(true);
    const feedAttachmentsArray = await handleFileUpload(fileArray);

    let feedObject = {
      content: formValues.content,
      attachmentArray: feedAttachmentsArray,
    };

    if (postDetail) {
      feedObject['relatedFeedId'] = postDetail?.id;
    }

    postFeed(feedObject)
      .then((response) => {
        if (response.status === 202) {
          postForm.resetFields();
          setIsMedia(false);
          openNotification('postCreated', 'success');
        } else {
          openNotification('postFailed', 'error');
        }
        setCreateButtonDisabled(true);
      })
      .finally(() => setPostLoading(false));
  };

  const tipTapOnChangeHandler = (tipTapValue) => {
    postForm.setFieldValue('content', tipTapValue);
    setEditorString(tipTapValue);
  };

  const backFireOfEmojiArea = () => {
    if (emojiPickerDisplay) {
      setEmojiPickerDisplay(!emojiPickerDisplay);
    }
  };

  return (
    <PrimaryCard>
      <Col span={24}>
        <Form
          initialValues={{
            content: '',
          }}
          className={postDetail ? 'w-full mb-4' : 'w-full'}
          onFinish={createPostHandler}
          form={postForm}
        >
          <Row gutter={[16, 0]} className={styles['postCreationRow']}>
            <Col span={24}>
              <Flex gap='1rem' className='mb-[1rem]'>
                <Link to={`/profile/${user?.username}`}>
                  <Image
                    width={55}
                    height={45}
                    className='rounded-[8px] object-cover'
                    preview={false}
                    src={
                      user?.userPhotos && user?.userPhotos[0]?.profile_photo
                        ? user?.userPhotos && user?.userPhotos[0]?.profile_photo
                        : defaultProfilePhoto
                    }
                  />
                </Link>
                <Link to={`/profile/${user?.username}`}>
                  <Text className='d-block'>{user?.username}</Text>
                  <span className={`${styles['userNick']} text-gray dark:text-dark-mode-gray`}>
                    {user?.first_name} {user?.last_name}
                  </span>
                </Link>
              </Flex>
            </Col>
            {isMedia && (
              <Col span={24}>
                <FileUploader
                  bucketCallBack={(file) => {
                    setFileArray(file);
                  }}
                  multiple={true}
                  formName={'media'}
                  placeholderInline='media'
                  accept={window.config.acceptedMediaTypes}
                />
              </Col>
            )}
            <Col span={24}>
              <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
                <Col span={24}>
                  <Form.Item name='content'>
                    <TipTap
                      isEmojiVisible={emojiPickerDisplay}
                      isGifVisible={gifPickerDisplay}
                      emojiViewHandler={() => setEmojiPickerDisplay(!emojiPickerDisplay)}
                      gifViewHandler={() => setGifPickerDisplay(!gifPickerDisplay)}
                      forwardOnChange={tipTapOnChangeHandler}
                    />
                  </Form.Item>
                </Col>
                <Col className={styles['actionWrapper']} span={6}>
                  <Flex justify='end' gap='1rem'>
                    <FileImageTwoTone
                      onClick={() => setIsMedia(!isMedia)}
                      twoToneColor={focusedPrimaryColor}
                      className='justify-center w-[32px] p-2 hover:bg-primary dark:hover:bg-primary dark:hover:text-white dark:bg-dark-mode-shadow-color dark:text-white rounded-full transition-all'
                    />
                    <SmileOutlined
                      onClick={() => {
                        setEmojiPickerDisplay(!emojiPickerDisplay);
                        setGifPickerDisplay(false);
                      }}
                      className='justify-center w-[32px] p-2 hover:bg-primary dark:hover:bg-primary dark:hover:text-white dark:bg-dark-mode-shadow-color dark:text-white rounded-full transition-all'
                    />
                    <GifOutlined
                      onClick={() => {
                        setGifPickerDisplay(!gifPickerDisplay);
                        setEmojiPickerDisplay(false);
                      }}
                      className='justify-center w-[32px] p-2 hover:bg-primary dark:hover:bg-primary dark:hover:text-white dark:bg-dark-mode-shadow-color dark:text-white rounded-full transition-all'
                    />
                    <Button
                      className='dark:border-dark-mode-text-color'
                      loading={postLoading}
                      type='primary'
                      htmlType='submit'
                      disabled={createButtonDisabled}
                    >
                      <Text className='text-inherit dark:text-white'>
                        <FormattedMessage id='create' />
                      </Text>
                    </Button>
                  </Flex>
                </Col>
              </OutsideClickDetector>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={24}>
        {postDetail && <PostCard reposted={true} postId={postDetail?.id} postDetail={postDetail} />}
      </Col>
    </PrimaryCard>
  );
};

export default CreatePostForm;
