import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Image,
  Input,
  Row,
  Space,
  Typography,
  List,
  Flex,
  Divider,
} from 'antd';
import styles from '../../style/mainHeader.module.scss';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  BellOutlined,
  CalendarOutlined,
  HomeOutlined,
  MessageOutlined,
  ProfileOutlined,
  ShopOutlined,
  TeamOutlined,
  CloseOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  REQUIRED_RULE,
  specialCharacters,
  truncateText,
} from '../../helpers/constants/constantHelpers';
import { CountrySelector, SelectComponent } from '../form/filters/Inputs';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useGetNotifications, useGetRequests } from '../../hooks/queries/UserQueries';
import { UtilityContext } from '../../providers/UtilityProvider';
import { uploadFile } from '../../requests/UtilityManager';
import { useClientError } from '../../hooks/useClientError';
import { useCreateOrganizations } from '../../hooks/queries/OrganizationQueries';
import { useGetOrganizationDetailByCodeHandler } from '../../hooks/queries/OrganizationQueries';
import CropperComponent from '../../helpers/utils/CropperComponent.tsx';

const { Text, Link } = Typography;

const LinksWithProfileMobile = () => {
  const { user, logout, userOwnedPages, hasUnreadMessages = [] } = useContext(AuthContext);
  const [teamDrawerOpen, setTeamDrawerOpen] = useState(false);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeSlug, setCodeSlug] = useState('');
  const [teamForm] = Form.useForm();
  const [imageObject, setImageObject] = useState(null);
  const translator = useIntl();
  const { data: notifications } = useGetNotifications(user?.id);
  const { data: requests = [] } = useGetRequests(user?.id);
  const { openNotification, organizationCategories, theme } = useContext(UtilityContext);
  const { mutateAsync: createOrganization, isPending: createOrganizationPending } =
    useCreateOrganizations(user?.id);
  const { data: organizationByCode } = useGetOrganizationDetailByCodeHandler(codeSlug);

  const onClientError = useClientError();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const teamNameSlugCreator = (inputValue) => {
    //Karakterler ingilizce karaktere çevrilmeli, küçük I küçük i olmalı Ü u olmalı gibi
    let charMap = {
      Ç: 'C',
      Ö: 'O',
      Ş: 'S',
      İ: 'I',
      I: 'i',
      Ü: 'U',
      Ğ: 'G',
      ç: 'c',
      ö: 'o',
      ş: 's',
      ı: 'i',
      ü: 'u',
      ğ: 'g',
    };

    let str_array = inputValue.split('');

    for (let i = 0, len = str_array.length; i < len; i++) {
      str_array[i] = charMap[str_array[i]] || str_array[i];
    }

    let newValue = str_array.join('').replace(/[çöşüğı]/gi, '');
    let slug = newValue.toLocaleLowerCase().replaceAll(' ', '-');

    if (slug[slug.length - 1] !== '-') {
      setCodeSlug(slug);
    }
  };

  useEffect(() => {
    if (codeSlug !== '' && organizationByCode?.code === codeSlug) {
      let randomNum = Math.floor(Math.random() * 10);
      setCodeSlug(`${codeSlug}_${randomNum}`);
    }
    teamForm.setFieldValue('code', codeSlug);
  }, [codeSlug, organizationByCode]);

  const createTeamHandler = () => {
    let formValues = teamForm.getFieldsValue();
    if (specialCharacters.test(formValues.name)) {
      openNotification('organizationNameNotAccepted', 'error');
    } else {
      teamForm.submit();
    }
  };

  const createOrganizationHandler = (formValues) => {
    setLoading(true);
    if (imageObject?.length === 0 || !imageObject) {
      setLoading(false);
      return openNotification('missingLogo', 'error');
    }

    uploadFile(imageObject)
      .then((response) => {
        if (response.status === 202) {
          formValues['logo_url'] = response.data.data;
          formValues['userId'] = user?.id;
          createOrganization(formValues)
            .then((response) => {
              if (response.status === 202) {
                openNotification('organizationCreated', 'success');
              } else {
                openNotification('organizationCreateFailed', 'error');
              }
            })
            .catch((err) => {
              onClientError(err);
            })
            .finally(() => {
              teamForm.resetFields();
              setCodeSlug('');
              setTeamDrawerOpen(false);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        onClientError(err);
        setLoading(false);
      });
  };

  const organizationLinks = userOwnedPages.map((page) => (
    <NavLink
      key={page.id}
      to={`/organization/${page.code}`}
      onClick={() => setUserDrawerOpen(false)}
    >
      <Text className='text-xl'>{truncateText(page.name, 20)}</Text>
    </NavLink>
  ));

  return (
    <div className={`${styles['mobileMenu']}  bg-white dark:bg-dark-mode-light-container-color`}>
      <div className={styles['mainHeader']}>
        <Col
          id='linkWrapper'
          className={styles['linkWrapper']}
          xxl={14}
          xl={14}
          lg={14}
          md={24}
          sm={24}
          xs={24}
        >
          <NavLink
            className={({ isActive }) => {
              return isActive
                ? 'text-[#b3278d] dark:dark-mode-primary-active-text-color hover:!dark-mode-primary-active-text-color flex flex-col items-center text-sm'
                : 'dark:text-dark-mode-text-color hover:!text-link-hover-color flex flex-col items-center text-sm';
            }}
            to='/feed'
            onClick={() => {
              if (pathname === '/feed') {
                navigate('/feed');
                window.location.reload();
              }
            }}
          >
            <HomeOutlined className={styles['linkIcon']} />
          </NavLink>
          {/*<NavLink className={styles['disabledLink']} to='/lobby'>
            <FontAwesomeIcon icon={faUsersViewfinder} />
          </NavLink>*/}
          <NavLink
            className={({ isActive }) => {
              return isActive
                ? 'text-[#b3278d] dark:dark-mode-primary-active-text-color hover:!dark-mode-primary-active-text-color flex flex-col items-center text-sm'
                : 'dark:text-dark-mode-text-color hover:!text-link-hover-color flex flex-col items-center text-sm';
            }}
            to='/events'
          >
            <CalendarOutlined className={styles['linkIcon']} />
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              return isActive
                ? 'text-[#b3278d] dark:text-dark-mode-primary-active-text-color hover:!dark-mode-primary-active-text-color flex flex-col items-center text-sm'
                : 'dark:text-dark-mode-text-color hover:!text-link-hover-color flex flex-col items-center text-sm';
            }}
            to='/messages'
          >
            <MessageOutlined className={styles['linkIcon']} />
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              return isActive
                ? 'text-[#b3278d] dark:text-dark-mode-primary-active-text-color hover:!dark-mode-primary-active-text-color flex flex-col items-center text-sm'
                : 'dark:text-dark-mode-text-color hover:!text-link-hover-color flex flex-col items-center text-sm';
            }}
            to='/notifications'
          >
            <div className={styles['notifications']}>
              <BellOutlined className={styles['linkIcon']} />
              {notifications?.filter((x) => !x.read).length > 0 || requests.length > 0 ? (
                <i></i>
              ) : (
                ''
              )}
            </div>
          </NavLink>
          <div className={styles['userDropdownWrapper']}>
            <Col span={24} className={styles['userInfoWrapper']}>
              <Image
                className='rounded-[8px]'
                width={55}
                height={45}
                preview={false}
                src={
                  user?.userPhotos && user.userPhotos[0]?.profile_photo
                    ? user?.userPhotos && user.userPhotos[0]?.profile_photo
                    : defaultProfilePhoto
                }
                onClick={() => setUserDrawerOpen(true)}
              />
            </Col>
          </div>
          <Drawer
            styles={{
              content: {
                background: theme ? '#2A2A2A' : 'white',
              },
              header: { background: theme ? '#2A2A2A' : 'white' },
            }}
            closeIcon={
              <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
            }
            width={720}
            destroyOnClose={true}
            onClose={() => setUserDrawerOpen(false)}
            open={userDrawerOpen}
          >
            <Flex vertical={true} align='center' gap='2rem'>
              <div className='text-2xl flex text-dark-mode-primary-dark'>
                <UserOutlined className='mr-2 text-2xl' />
                <FormattedMessage id='account' />
              </div>
              <NavLink to={`/profile/${user?.username}`} onClick={() => setUserDrawerOpen(false)}>
                <Text className='text-xl'>
                  <FormattedMessage id='seeProfile' />
                </Text>
              </NavLink>
              <NavLink to={'/settings'} onClick={() => setUserDrawerOpen(false)}>
                <Text className='text-xl'>
                  <FormattedMessage id='settings' />
                </Text>
              </NavLink>
              <Link onClick={logout}>
                <Text className='text-xl'>
                  <FormattedMessage id='logout' />
                </Text>
              </Link>
              <Divider className='my-0' />
              <div className='text-2xl flex text-dark-mode-primary-dark'>
                <TeamOutlined className='mr-2 text-2xl' />
                <FormattedMessage id='team' />
              </div>
              <Button
                className={`${styles['ghostButtonText']} `}
                onClick={() => setTeamDrawerOpen(true)}
              >
                <Text className='text-xl'>
                  <FormattedMessage id='createOrganization' />
                </Text>
              </Button>
              <Divider className='my-0' />
              <div className='text-2xl flex text-dark-mode-primary-dark'>
                <ShopOutlined className='mr-2 text-2xl' />
                <FormattedMessage id='myOrganizations' />
              </div>
              {organizationLinks}
            </Flex>
          </Drawer>

          <Drawer
            styles={{
              content: {
                background: theme ? '#2A2A2A' : 'white',
              },
              header: { background: theme ? '#2A2A2A' : 'white' },
            }}
            closeIcon={
              <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
            }
            title={translator.formatMessage({ id: 'createOrganization' })}
            width={720}
            destroyOnClose={true}
            onClose={() => setTeamDrawerOpen(false)}
            open={teamDrawerOpen}
            extra={
              <Space>
                <Button onClick={() => setTeamDrawerOpen(false)}>
                  <Text className='text-inherit'>
                    <FormattedMessage id='cancel' />
                  </Text>
                </Button>
                <Button loading={loading} onClick={createTeamHandler} type='primary'>
                  <Text className='text-inherit'>
                    <FormattedMessage id='submit' />
                  </Text>
                </Button>
              </Space>
            }
          >
            <Form
              initialValues={{
                slug: translator.formatMessage({ id: 'organizationUrl' }),
              }}
              form={teamForm}
              layout='vertical'
              onFinish={createOrganizationHandler}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name='name'
                    label={translator.formatMessage({ id: 'name' })}
                    rules={[
                      { required: true, message: '' },
                      {
                        max: 30,
                        message: translator.formatMessage({ id: 'formLengthMessageShort' }),
                      },
                    ]}
                  >
                    <Input
                      className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
                      onChange={(element) => teamNameSlugCreator(element.target.value)}
                      placeholder={translator.formatMessage({ id: 'enterOrganizationName' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='code' label={translator.formatMessage({ id: 'yourUrl' })}>
                    <Input
                      className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
                      style={{
                        width: '100%',
                      }}
                      disabled={true}
                      placeholder={translator.formatMessage({ id: 'organizationUrl' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <CountrySelector isRequired={true} formName='country' />
                </Col>
                <Col span={24}>
                  <SelectComponent
                    labelId='category'
                    translationId='category'
                    serviceUrl='getCategories'
                    options={organizationCategories}
                    fieldNames={{
                      label: 'name',
                      value: 'id',
                    }}
                    formName='category_id'
                    required={true}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name='description'
                    label={translator.formatMessage({ id: 'description' })}
                    rules={[
                      {
                        max: 250,
                        message: translator.formatMessage({ id: 'formLengthMessageLong' }),
                      },
                    ]}
                  >
                    <Input.TextArea
                      className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
                      style={{ height: 100 }}
                      rows={4}
                      placeholder={translator.formatMessage({ id: 'enterDescriptionForTeam' })}
                    />
                  </Form.Item>
                  <CropperComponent
                    isLoading={loading}
                    label='addTeamLogo'
                    returnedData={(value) => setImageObject(value)}
                    formName='logo_url'
                    required={true}
                    forOrganization={true}
                  />
                </Col>
              </Row>
            </Form>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Text className='dark:text-dark-mode-text-color' italic type='secondary'>
                  <FormattedMessage id='organizationPageDescription' />
                </Text>
              </Col>
              <Col span={24}>
                <Text className='dark:text-dark-mode-text-color' italic type='secondary'>
                  <FormattedMessage id='organizationPageSettingInfo' />
                </Text>
              </Col>
            </Row>
          </Drawer>
        </Col>
      </div>
    </div>
  );
};

export default LinksWithProfileMobile;
