import React, { useContext, useState } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from '../components/hoc/ScrollToTop';
import InnerLayout from './InnerLayout';
import trTR from 'antd/locale/tr_TR';
import enEN from 'antd/locale/en_US';
import variables from '../style/_variables.module.scss';
import { UtilityContext } from '../providers/UtilityProvider';
import MainHeader from '../components/header/MainHeader';
import dayjs from 'dayjs';
import useIsPath from '../hooks/useIsPath';
import { useParams } from 'react-router-dom';
const LayoutContent = ({ lang }) => {
  const { theme, isUserKey } = useContext(UtilityContext);

  const { Content } = Layout;
  const params = useParams();

  const {
    primaryColor,
    lightContainerColor,
    linkHoverColor,
    focusedPrimaryColor,
    bodyTextColor,
    backgroundColor,
    primaryShadow,
    colorLink,
    tableRowHoverBg,
    tableBorderColor,
    themeLight,
    colorTextPlaceHolder,
    white,

    darkModePrimaryColor,
    darkModeLightContainerColor,
    darkModeBorderColor,
    darkModeBgPopUp,
    darkModeTextColor,
    darkModeCream,
    colorTextDisabled,
    calendarTextColor,
    darkModeSecondaryColor,
    deleteColor,
    darkModeNotificationsBorderColor,
    darkModeFocusedPrimaryColor,
    darkModeLinkHoverColor,
    darkModeBackgroundColor,
    darkModePrimaryDark,
  } = variables;

  let isHeaderVisible = !useIsPath('/login');

  if (
    window.location.pathname === '/' ||
    window.location.pathname === '/privacy-policy' ||
    window.location.pathname === '/terms-of-service' ||
    window.location.pathname === `/activate/${isUserKey}`
  ) {
    isHeaderVisible = false;
  }

  if (window.location.pathname.includes('/reset-password')) {
    isHeaderVisible = false;
  }

  //   let lang = 'tr';

  //   if (lang === 'tr') {
  //     dayjs.locale('tr');
  //   } else {
  //     dayjs.locale('en');
  //   }
  return (
    <ConfigProvider
      locale={lang === 'tr' ? trTR : enEN}
      theme={{
        components: {
          DatePicker: {
            colorBgContainer: theme ? darkModeLightContainerColor : white,
            colorBorder: theme ? darkModeBorderColor : '',
            colorBgElevated: theme ? darkModeBgPopUp : '',
            colorTextPlaceholder: theme ? darkModeTextColor : '',
            cellHoverBg: theme ? darkModeCream : '',
            optionSelectedColor: theme ? darkModePrimaryColor : primaryColor,
            colorTextDisabled: theme ? colorTextDisabled : '',
          },
          Dropdown: {
            colorBgElevated: theme ? darkModeBgPopUp : white,
          },
          Pagination: {
            colorBgContainer: theme ? 'transparent' : 'transparent',
            colorTextDisabled: theme ? darkModePrimaryColor : primaryColor,
          },
          Modal: {
            contentBg: theme ? darkModeLightContainerColor : white,
            headerBg: theme ? darkModeLightContainerColor : white,
          },
          Drawer: {
            contentBg: theme ? darkModeLightContainerColor : white,
            headerBg: theme ? darkModeLightContainerColor : white,
          },
          Popover: {
            colorBgElevated: theme ? darkModeLightContainerColor : white,
          },
          Select: {
            selectorBg: theme ? darkModeLightContainerColor : white,
            colorBorder: theme ? darkModeBorderColor : '',
            optionSelectedColor: theme ? darkModePrimaryColor : primaryColor,
            colorTextPlaceholder: theme ? darkModeTextColor : '',
            colorText: theme ? darkModeTextColor : '',
            colorBgElevated: theme ? darkModeBgPopUp : white,
          },

          Calendar: {
            fullBg: theme ? darkModeLightContainerColor : lightContainerColor,
            colorText: theme ? calendarTextColor : '',
            colorTextDisabled: theme ? colorTextDisabled : '',
            controlItemBgHover: darkModeSecondaryColor,
            itemActiveBg: theme ? darkModeLightContainerColor : lightContainerColor,
          },
          Button: {
            colorError: deleteColor,
            fontWeight: 600,
            primaryShadow: primaryShadow,
            fontSize: 12,
            colorBgContainer: 'transparent',
          },
          Card: {
            colorBgContainer: theme ? darkModeLightContainerColor : lightContainerColor,
            paddingLG: 16,
          },
          Divider: {
            marginLG: 16,
          },
          Table: {
            headerBg: theme ? darkModeLightContainerColor : white,
            borderColor: theme ? darkModeNotificationsBorderColor : tableBorderColor,
            rowExpandedBg: theme ? darkModeLightContainerColor : white,
            rowHoverBg: theme ? darkModeBgPopUp : tableRowHoverBg,
            colorBgContainer: theme ? darkModeLightContainerColor : white,
            expandIconBg: white,
          },
          Radio: {
            buttonBg: theme ? darkModeLightContainerColor : white,
            buttonCheckedColorDisabled: theme ? darkModeLightContainerColor : white,
            buttonCheckedBg: theme ? darkModeLightContainerColor : white,
          },
        },
        token: {
          colorSplit: theme ? darkModeNotificationsBorderColor : '#d9d9d9',
          colorPrimary: theme ? darkModePrimaryColor : primaryColor,
          colorPrimaryText: theme ? darkModePrimaryColor : primaryColor,
          colorPrimaryTextActive: theme ? darkModeFocusedPrimaryColor : focusedPrimaryColor,
          colorLink: colorLink,
          colorLinkActive: theme ? darkModePrimaryColor : primaryColor,
          colorLinkHover: theme ? darkModeLinkHoverColor : linkHoverColor,
          colorText: theme ? darkModeTextColor : bodyTextColor,
          colorBorder: theme ? darkModeNotificationsBorderColor : '#d9d9d9',
          colorIcon: theme ? darkModeTextColor : '',
          colorIconHover: theme ? darkModePrimaryColor : primaryColor,
          colorBgLayout:
            window.location.pathname === '/'
              ? theme
                ? darkModeBackgroundColor
                : themeLight
              : theme
              ? darkModeBackgroundColor
              : backgroundColor,
          borderRadiusLG: 12,
          borderRadiusSM: 6,
          colorTextPlaceholder: colorTextPlaceHolder,
        },
      }}
    >
      <Layout>
        {isHeaderVisible && (
          <Header
            style={{ boxShadow: theme && '0 0 6px 0 black' }}
            className='!bg-white dark:!bg-dark-mode-light-container-color '
          >
            <MainHeader />
          </Header>
        )}
        <Content>
          <ToastContainer
            position='top-right'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={theme ? 'dark' : 'light'}
          />
          <ScrollToTop />
          <InnerLayout />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutContent;
