import { Button, Col, Flex, Image, Popconfirm, Row, Typography, Drawer, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Reply from './Reply';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  CloseOutlined,
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import layoutStyles from '../../style/layout.module.scss';
import { faBomb } from '@fortawesome/free-solid-svg-icons';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { useGetLikes, useGetReplies, useLikeHandler } from '../../hooks/queries/PostActionQueries';
import CreateReply from './CreateReply';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { getRelativeTime } from '../../helpers/utils/TimeFormatters';
import styles from '../../style/postpage.module.scss';
import typographyStyles from '../../style/typography.module.scss';
import { useDeleteFeed } from '../../hooks/queries/FeedQueries';
import { UtilityContext } from '../../providers/UtilityProvider';
import { useClientError } from '../../hooks/useClientError';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import ListUsers from '../feed/ListUsers';
import useWindowSize from '../../hooks/useWindowSize';
import EditComment from './EditComment';
import { differenceInMinutes } from 'date-fns';

const { Paragraph, Text } = Typography;

const Comment = ({ details, owner }) => {
  const { user } = useContext(AuthContext);
  const { openNotification, theme } = useContext(UtilityContext);
  const [commentVisibility, setCommentVisibility] = useState(false);
  const [commentPage, setCommentPage] = useState(1);
  const [comments, setComments] = useState([]);
  const commentRef = useRef();
  const { width } = useWindowSize();
  const { mutateAsync: deleteComment, isLoading: deleteLoading } = useDeleteFeed('comment');
  const { isLoading, isFetching, data } = useGetReplies(details?.id, commentPage, 2);
  const {
    data: likes,
    fetchNextPage,
    isLoading: likeListLoading,
  } = useGetLikes(details?.id, user?.id);
  const [likedList, setLikedList] = useState(false);
  const { mutate: mutateLike } = useLikeHandler();
  const [editModal, setEditModal] = useState(false);

  const translator = useIntl();
  const onClientError = useClientError();

  const closeEditModal = () => {
    setEditModal(false);
  };

  useEffect(() => {
    if (details && commentRef.current) {
      commentRef.current.innerHTML = details.content;
    }
  }, [details, commentRef.current]);

  useEffect(() => {
    let newCommentArray = [...comments];

    if (data) {
      for (let comment of data.rows) {
        if (!newCommentArray.some((c) => c.id === comment.id)) {
          newCommentArray.push(comment);
        } else if (
          newCommentArray.some((c) => c.id === comment.id) &&
          !newCommentArray.some((c) => c.content === comment.content)
        ) {
          newCommentArray.pop(newCommentArray.some((c) => c.content === comment.content));
          newCommentArray.push(comment);
        }
      }
      newCommentArray = newCommentArray.filter((c) =>
        data.rows.some((comment) => comment.id === c.id),
      );
      setComments(newCommentArray);
    }
  }, [data]);

  const setCommentVisibilityHandler = () => {
    setCommentVisibility(!commentVisibility);
  };

  const showMoreReply = () => {
    setCommentPage(commentPage + 1);
  };

  const likeHandler = () => {
    mutateLike(details?.id);
  };

  const deleteCommentHandler = () => {
    deleteComment(details?.id)
      .then((response) => {
        if (response.status === 202) {
          openNotification('commentDeleted', 'success');
        } else {
          openNotification('commentDeleteFailed', 'error');
        }
      })
      .catch((err) => {
        onClientError(err);
      });
  };

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Row className={styles['commentRow']} gutter={[8, 0]}>
      <Col span={24}>
        <Flex gap='1rem'>
          <Link to={`/profile/${details?.user.username}`}>
            <Image
              className='rounded-[8px] object-cover'
              height={45}
              width={55}
              src={
                details?.user.userPhotos[0]?.profile_photo
                  ? details?.user.userPhotos[0]?.profile_photo
                  : defaultProfilePhoto
              }
              preview={false}
            />
          </Link>
          <div
            className={`${styles['commentWrapper']} w-full bg-white dark:bg-dark-mode-white border border-solid border-background-color dark:border-dark-mode-edit-hover-color-background`}
          >
            <Flex justify='space-between'>
              <div className={`${styles['userInfo']} !mb-4`}>
                <Link to={`/profile/${details?.user.username}`}>
                  <Text className={styles['userName']}>
                    {details?.user.username}
                    <span className={`${styles['userNick']} text-gray dark:text-dark-mode-gray`}>
                      • {details?.user.first_name} {details?.user.last_name}
                    </span>
                  </Text>
                </Link>
                <span
                  className={`${typographyStyles['blueFont']} !text-xs !text-gray dark:!text-dark-mode-gray `}
                >
                  {getRelativeTime(details?.created_at)}
                </span>
                {differenceInMinutes(details?.updated_at, details?.created_at) >= 1 && (
                  <span className='!text-xs !text-gray-600 ml-1'>
                    {' '}
                    {'('}
                    <FormattedMessage id='edited' />
                    {')'}
                  </span>
                )}
              </div>
              <div>
                {details?.user?.id === user?.id && (
                  <Button
                    onClick={() => setEditModal(true)}
                    type='link'
                    icon={
                      <EditOutlined className='cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full' />
                    }
                  ></Button>
                )}
                {(details?.user?.id === user?.id || owner === user?.id) && (
                  <Popconfirm
                    title={translator.formatMessage({ id: 'attention' })}
                    description={translator.formatMessage({ id: 'deleteCommentDescription' })}
                    onConfirm={deleteCommentHandler}
                    okText={translator.formatMessage({ id: 'delete' })}
                    cancelText={translator.formatMessage({ id: 'cancel' })}
                    okButtonProps={{ loading: deleteLoading, icon: <DeleteOutlined /> }}
                    cancelButtonProps={{ icon: <CloseOutlined /> }}
                  >
                    <DeleteOutlined className='cursor-pointer !text-red-600 hover:bg-red-200 p-2 rounded-full' />
                  </Popconfirm>
                )}
              </div>
            </Flex>
            <Paragraph
              ref={commentRef}
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: <FormattedMessage id='more' />,
              }}
            />
          </div>
        </Flex>
      </Col>
      <Col span={24}>
        <Row className={styles['commentActions']}>
          <Flex vertical>
            <div className={styles['postInfo']}>
              {likes?.pages[0].like_count > 0 && (
                <Text
                  className='cursor-pointer'
                  onClick={() => {
                    setLikedList(true);
                  }}
                >
                  {likes?.pages[0].like_count} <FormattedMessage id='likeCountMessage' />
                </Text>
              )}
            </div>
            <Flex gap='1rem' className={styles['postInfo']}>
              <Button
                onClick={likeHandler}
                className={styles['hitIconButton']}
                icon={
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faBomb}
                    style={{ color: likes?.pages[0].is_liked ? '#c31d1d' : '#959595' }}
                  />
                }
              >
                <Text className='text-inherit mt-1 !text-[#b3b3b3]'>Hit</Text>
              </Button>
              <Button
                type={commentVisibility ? 'primary' : 'default'}
                onClick={setCommentVisibilityHandler}
                icon={<CommentOutlined style={{ color: commentVisibility ? '#FFF' : '#959595' }} />}
              />
            </Flex>
          </Flex>
        </Row>
      </Col>
      {commentVisibility && (
        <Col span={24}>
          <CreateReply commentId={details?.id} />
        </Col>
      )}
      <Col span={24}>
        <>
          {comments?.map((reply) => (
            <Reply key={reply?.id} reply={reply} />
          ))}
        </>
        {details?.comment_count > comments.length &&
          (isFetching ? (
            <SpinWrapper />
          ) : (
            <div className={styles['showMoreReply']} onClick={showMoreReply}>
              <PlusCircleOutlined className='fill-primary-color dark:fill-dark-mode-primary-color' />
            </div>
          ))}
      </Col>
      <Drawer
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={320}
        destroyOnClose={true}
        onClose={() => setLikedList(false)}
        open={likedList}
        placement={width < 480 ? 'bottom' : 'right'}
      >
        <ListUsers fetchHandler={fetchNextPage} isLoading={likeListLoading} list={likes} />
      </Drawer>
      <Modal
        title={translator.formatMessage({ id: 'edit' })}
        open={editModal}
        onCancel={() => setEditModal(false)}
        footer={null}
        width={840}
        destroyOnClose={true}
      >
        <EditComment postDetails={details} closeModal={closeEditModal} />
      </Modal>
    </Row>
  );
};

export default Comment;
