import React from 'react';
import { Flex, Image, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useGetOrganizationDetailByCodeHandler } from '../../hooks/queries/OrganizationQueries';

const InviteOrganizationNotification = ({ notification }) => {
  const { data: organizationDetails } = useGetOrganizationDetailByCodeHandler(
    notification.related_parameter,
  );
  const Text = Typography;
  const getLanguageBrowser = navigator.language;
  let timeStamp = dateFormatter(notification.created_at);

  return (
    <div className='w-full bg-white dark:bg-dark-mode-bg-pop-up dark:hover:bg-dark-mode-cream p-4 border-0 border-b border-solid border-white-gray dark:border-dark-mode-notifications-border-color margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/organization/${organizationDetails?.code}`}>
          <Image
            className='rounded-[8px] object-cover'
            width={55}
            height={45}
            preview={false}
            src={organizationDetails?.logo_url}
          />
        </Link>
        <Link to={`/organization/${organizationDetails?.code}`}>
          <Text>
            {getLanguageBrowser === 'en' ? (
              <FormattedMessage id='inviteOrganizationNotification' />
            ) : (
              ''
            )}
            {organizationDetails?.name} {}
            {getLanguageBrowser === 'tr' ? (
              <FormattedMessage id='inviteOrganizationNotification' />
            ) : (
              ''
            )}
          </Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default InviteOrganizationNotification;
