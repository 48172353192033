import { Button, Col, Flex, Image, Row, Typography, Modal, Popconfirm, Drawer } from 'antd';
import styles from '../../style/postpage.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import layoutStyles from '../../style/layout.module.scss';
import { faBomb } from '@fortawesome/free-solid-svg-icons';
import { useGetLikes, useLikeHandler } from '../../hooks/queries/PostActionQueries';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import typographyStyles from '../../style/typography.module.scss';
import { getRelativeTime } from '../../helpers/utils/TimeFormatters';
import { Link } from 'react-router-dom';
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDeleteFeed } from '../../hooks/queries/FeedQueries';
import useWindowSize from '../../hooks/useWindowSize';
import { AuthContext } from '../../providers/AuthProvider';
import { UtilityContext } from '../../providers/UtilityProvider';
import ListUsers from '../feed/ListUsers';
import EditComment from './EditComment';
import { useClientError } from '../../hooks/useClientError';
import { differenceInMinutes } from 'date-fns';

const { Paragraph, Text } = Typography;

const Reply = ({ reply, owner }) => {
  const { mutate } = useLikeHandler();
  const { openNotification } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const replyRef = useRef();
  const [editModal, setEditModal] = useState(false);
  const [likedList, setLikedList] = useState(false);
  const { mutateAsync: deleteComment, isLoading: deleteLoading } = useDeleteFeed('comment');
  const {
    data: likes,
    fetchNextPage,
    isLoading: likeListLoading,
  } = useGetLikes(reply?.id, user?.id);
  const { width } = useWindowSize();

  const translator = useIntl();
  const onClientError = useClientError();

  const closeEditModal = () => {
    setEditModal(false);
  };

  const likeHandler = () => {
    mutate(reply?.id);
  };

  const deleteCommentHandler = () => {
    deleteComment(reply?.id)
      .then((response) => {
        if (response.status === 202) {
          openNotification('commentDeleted', 'success');
        } else {
          openNotification('commentDeleteFailed', 'error');
        }
      })
      .catch((err) => {
        onClientError(err);
      });
  };

  useEffect(() => {
    if (reply && replyRef.current) {
      replyRef.current.innerHTML = reply.content;
    }
  }, [reply, replyRef.current]);

  return (
    <Row gutter={[8, 0]} className={styles['replyRow']}>
      <Col span={24}>
        <Flex gap='1rem'>
          <Link to={`/profile/${reply?.user.username}`}>
            <Image
              className='rounded-[8px] object-cover'
              height={45}
              width={55}
              src={
                reply.user.userPhotos[0]?.profile_photo
                  ? reply.user.userPhotos[0]?.profile_photo
                  : defaultProfilePhoto
              }
              preview={false}
            />
          </Link>
          <div
            className={`${styles['commentWrapper']} w-full bg-white dark:bg-dark-mode-white border border-solid border-background-color dark:border-dark-mode-edit-hover-color-background`}
          >
            <Flex justify='space-between'>
              <div style={{ marginBottom: '1rem' }} className={styles['userInfo']}>
                <Link to={`/profile/${reply?.user.username}`}>
                  <Text className={styles['userName']}>
                    {reply.user.username}
                    <span className={`${styles['userNick']} text-gray dark:text-dark-mode-gray`}>
                      • {reply.user.first_name} {reply.user.last_name}
                    </span>
                  </Text>
                </Link>
                <span
                  className={`${typographyStyles['blueFont']} !text-xs !text-gray dark:!text-dark-mode-gray `}
                >
                  {getRelativeTime(reply?.created_at)}
                </span>
                {differenceInMinutes(reply?.updated_at, reply?.created_at) >= 1 && (
                  <span className='!text-xs !text-gray-600 ml-1'>
                    {' '}
                    {'('}
                    <FormattedMessage id='edited' />
                    {')'}
                  </span>
                )}
              </div>
              <div>
                {reply?.user?.id === user?.id && (
                  <Button
                    onClick={() => setEditModal(true)}
                    type='link'
                    icon={
                      <EditOutlined className='cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full' />
                    }
                  ></Button>
                )}
                {(reply?.user?.id === user?.id || owner === user?.id) && (
                  <Popconfirm
                    title={translator.formatMessage({ id: 'attention' })}
                    description={translator.formatMessage({ id: 'deleteCommentDescription' })}
                    onConfirm={deleteCommentHandler}
                    okText={translator.formatMessage({ id: 'delete' })}
                    cancelText={translator.formatMessage({ id: 'cancel' })}
                    okButtonProps={{ loading: deleteLoading, icon: <DeleteOutlined /> }}
                    cancelButtonProps={{ icon: <CloseOutlined /> }}
                  >
                    <DeleteOutlined className='cursor-pointer !text-red-600 hover:bg-red-200 p-2 rounded-full' />
                  </Popconfirm>
                )}
              </div>
            </Flex>
            <Paragraph
              ref={replyRef}
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: <FormattedMessage id='more' />,
              }}
            />
          </div>
        </Flex>
      </Col>
      <Col span={24}>
        <Row className={styles['commentActions']}>
          <Button
            onClick={likeHandler}
            icon={
              <FontAwesomeIcon
                className={layoutStyles['fontAwesomeIcon']}
                icon={faBomb}
                style={{ color: likes?.pages[0].is_liked ? '#c31d1d' : '#959595' }}
              />
            }
          >
            <Text className='text-sm text-inherit mt-1'>Hit</Text>
          </Button>
          <div className={styles['postInfo']}>
            {likes?.pages[0].like_count > 0 && (
              <Text
                className='cursor-pointer mt-2'
                onClick={() => {
                  setLikedList(true);
                }}
              >
                {likes?.pages[0].like_count} <FormattedMessage id='likeCountMessage' />
              </Text>
            )}
          </div>
        </Row>
      </Col>
      <Drawer
        width={320}
        destroyOnClose={true}
        onClose={() => setLikedList(false)}
        open={likedList}
        placement={width < 480 ? 'bottom' : 'right'}
      >
        <ListUsers fetchHandler={fetchNextPage} isLoading={likeListLoading} list={likes} />
      </Drawer>
      <Modal
        title={translator.formatMessage({ id: 'edit' })}
        open={editModal}
        onCancel={() => setEditModal(false)}
        footer={null}
        width={840}
        destroyOnClose={true}
      >
        <EditComment postDetails={reply} closeModal={closeEditModal} />
      </Modal>
    </Row>
  );
};

export default Reply;
