import { useParams } from 'react-router-dom';
import PostDetail from './PostDetail';
import PrimaryCard from '../cards/PrimaryCard';
import styles from '../../style/postpage.module.scss';
import { Col, Row } from 'antd';
import usePageSEO from '../../hooks/usePageSEO';
import RepostDetail from './RepostDetail';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { useGetFeedDetails } from '../../hooks/queries/FeedQueries';

const SharedPost = () => {
  const { uid } = useParams();
  const { data, isLoading } = useGetFeedDetails(null, uid);

  usePageSEO({
    title: 'Game Actor',
    description: 'Check out this post on Game Actor.',
    keywords: ['game', 'actor', 'posts', 'gaming', 'social', 'media', 'esport'],
    ogTitle: 'Game Actor',
    ogDescription: 'Check out this post on Game Actor.',
    ogImage: 'src/assets/images/game-actor-star-circle.png',
    ogUrl: window.location.href,
  });

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Row className={styles['postPageWrapper']} gutter={[16, 16]}>
      <Col className={styles['communityWrapper']} xxl={5} xl={5} lg={5} md={5} sm={0} xs={0}></Col>
      <Col className={styles['feedWrapper']} xxl={13} xl={13} lg={13} md={13} sm={24} xs={24}>
        <PrimaryCard
          grid={false}
          bodyStyle={{ width: '100%' }}
          className='w-full flex justify-center align-middle max-w-[740px] mx-auto'
        >
          {data?.relatedFeedId > 0 ? (
            data?.typeId === 1 ?
              (<RepostDetail postId={data?.id} postDetail={data}/>) :
              (<PostDetail key={data?.relatedFeedId} postId={data?.relatedFeedId} />)
          ) : (
          <PostDetail key={uid} share={uid} />
          )}
        </PrimaryCard> 
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={0} xs={0}></Col>
    </Row>
  );
};

export default SharedPost;
