import PrimaryCard from '../cards/PrimaryCard';
import { Col, Divider, Flex, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from '../../style/interestedGames.module.scss';
import AnimateHeight from '../utility/AnimateHeight';
import { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useGetTrendTags } from '../../hooks/queries/UtilityQueries';
import useWindowSize from '../../hooks/useWindowSize';

const { Text } = Typography;
const InterestedGames = () => {
  const { width } = useWindowSize();

  const [interestedGamesHeight, setInterestedGamesHeight] = useState(width < 760 ? 50 : 'auto');
  const { data } = useGetTrendTags();

  const heightHandler = () => {
    setInterestedGamesHeight(interestedGamesHeight === 50 ? 'auto' : 50);
  };

  return (
    <AnimateHeight
      className='overflow-hidden rounded-[8px] w-full'
      duration={500}
      height={interestedGamesHeight}
    >
      <PrimaryCard grid={false}>
        <Col className='flex justify-between items-center' span={24}>
          <span className={styles['interestedParagraph']}>
            <FormattedMessage id='trends' />
          </span>
          <DownOutlined
            onClick={heightHandler}
            className={`${styles['calendarAnimator']} ${
              interestedGamesHeight === 'auto' && 'rotate-180'
            } hover:bg-background-color dark:hover:bg-dark-mode-edit-hover-color-background`}
          />
        </Col>
        <Divider />
        <Flex gap='.5rem' vertical={true}>
          {data?.map(({ id, count, label }) => (
            <Link key={id} to={`/hash/${label.replaceAll('#', '$')}`}>
              <Text className='hover:text-link-hover-color'>
                {label} - {count}
              </Text>
            </Link>
          ))}
        </Flex>
      </PrimaryCard>
    </AnimateHeight>
  );
};

export default InterestedGames;
