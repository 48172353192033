import { Button, Card, Col, Descriptions, Image, Modal, Row, Typography } from 'antd';
import styles from '../../style/profile.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { SettingTwoTone, CloseOutlined } from '@ant-design/icons';
import {
  faBattleNet,
  faDiscord,
  faPlaystation,
  faSteam,
  faXbox,
} from '@fortawesome/free-brands-svg-icons';
import layoutStyles from '../../style/layout.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faCamera,
  faChair,
  faDesktop,
  faGears,
  faHeadphones,
  faKeyboard,
  faMemory,
  faMicrochip,
  faMicrophone,
  faMouse,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { GpuIcon } from '../../helpers/Icons';
import { useContext, useState } from 'react';
import SystemPropertiesForm from '../form/user/SystemPropertiesForm';
import PlatformForm from '../form/user/PlatformForm';
import AccessoryForm from '../form/user/AccessoryForm';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import epicImage from '../../assets/svg/epic-games.svg';
import riotGames from '../../assets/svg/riot-games.svg';
import {
  useAccessoryInformation,
  usePlatformInformation,
  useSystemInformation,
} from '../../hooks/queries/UserQueries';
import variables from '../../style/_variables.module.scss';
import PropChecker from '../../helpers/utils/PropChecker';
import EmptyWrapper from '../utility/wrappers/EmptyWrapper';
import { UtilityContext } from '../../providers/UtilityProvider';

const EquipmentInformation = ({ isOwner, profileDetails }) => {
  const {
    data: userSystemInformation,
    isLoading: systemInformationLoading,
    isSuccess: systemInformationSuccess,
  } = useSystemInformation(profileDetails?.id);
  const {
    data: userPlatformInformation,
    isLoading: platformInformationLoading,
    isSuccess: platformInformationSuccess,
  } = usePlatformInformation(profileDetails?.id);
  const {
    data: userAccessoryInformation,
    isLoading: accessoryInformationLoading,
    isSuccess: accessoryInformationSuccess,
  } = useAccessoryInformation(profileDetails?.id);

  const [systemPropertiesModal, setSystemPropertiesModal] = useState(false);
  const [platformsModal, setPlatformsModal] = useState(false);
  const [accessoryModal, setAccessoryModal] = useState(false);
  const { Text } = Typography;
  const translator = useIntl();
  const { focusedPrimaryColor } = variables;

  const { theme } = useContext(UtilityContext);
  const modalHandler = (modalParameter) => {
    if (modalParameter === 'sp') {
      setSystemPropertiesModal(!systemPropertiesModal);
    } else if (modalParameter === 'pl') {
      setPlatformsModal(!platformsModal);
    } else if (modalParameter === 'ac') {
      setAccessoryModal(!accessoryModal);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      {systemInformationLoading ? (
        <Col span={24}>
          <SpinWrapper />
        </Col>
      ) : systemInformationSuccess ? (
        <Col span={24}>
          <Card
            bordered={false}
            className={`${styles['antdCard']} card-profile-information header-solid h-full`}
            extra={
              isOwner && (
                <Button
                  onClick={() => modalHandler('sp')}
                  icon={<SettingTwoTone twoToneColor={focusedPrimaryColor} />}
                  type='link'
                />
              )
            }
            title={
              <h6 className='font-semibold m-0'>
                <Text>
                  <FormattedMessage id='systemProperties' />
                </Text>
              </h6>
            }
            styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
          >
            <Descriptions>
              {!PropChecker(userSystemInformation, 'userId') && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <EmptyWrapper imgStyle={{ height: '70px' }} />
                </Descriptions.Item>
              )}
              {userSystemInformation.cpu.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faMicrochip}
                    style={{ color: '#1e57d5', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userSystemInformation.cpu}</Text>
                </Descriptions.Item>
              )}
              {userSystemInformation.router.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faWifi}
                    style={{ color: '#1e57d5', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userSystemInformation.router}</Text>
                </Descriptions.Item>
              )}
              {userSystemInformation.memory.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faMemory}
                    style={{ color: '#1e57d5', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userSystemInformation.memory}</Text>
                </Descriptions.Item>
              )}
              {userSystemInformation.power_supply.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faBolt}
                    style={{ color: '#1e57d5', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userSystemInformation.power_supply}</Text>
                </Descriptions.Item>
              )}
              {userSystemInformation.gpu.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <GpuIcon />
                  <Text className='text-xs'>{userSystemInformation.gpu}</Text>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        </Col>
      ) : (
        'Error'
      )}
      {platformInformationLoading ? (
        <Col span={24}>
          <SpinWrapper />
        </Col>
      ) : platformInformationSuccess ? (
        <Col span={24}>
          <Card
            bordered={false}
            title={
              <h6 className='font-semibold m-0'>
                <FormattedMessage id='platforms' />
              </h6>
            }
            className={`${styles['antdCard']} header-solid h-full card-profile-information`}
            extra={
              isOwner && (
                <Button
                  onClick={() => modalHandler('pl')}
                  icon={<SettingTwoTone twoToneColor={focusedPrimaryColor} />}
                  type='link'
                />
              )
            }
            styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
          >
            {!PropChecker(userPlatformInformation, 'userId') && (
              <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                <EmptyWrapper imgStyle={{ height: '70px' }} />
              </Descriptions.Item>
            )}
            <Descriptions>
              {userPlatformInformation.xbox.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faXbox}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userPlatformInformation.xbox}</Text>
                </Descriptions.Item>
              )}
              {userPlatformInformation.discord?.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faDiscord}
                    style={{ color: '#5765f2', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userPlatformInformation.discord}</Text>
                </Descriptions.Item>
              )}
              {userPlatformInformation.battlenet.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faBattleNet}
                    style={{ color: '#158eff', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userPlatformInformation.battlenet}</Text>
                </Descriptions.Item>
              )}
              {userPlatformInformation.steam.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faSteam}
                    style={{ color: '#192b3c', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userPlatformInformation.steam}</Text>
                </Descriptions.Item>
              )}
              {userPlatformInformation.psn.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faPlaystation}
                    style={{ color: '#0070d1', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userPlatformInformation.psn}</Text>
                </Descriptions.Item>
              )}
              {userPlatformInformation.epic.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <div className='mr-[7px]'>
                    <Image
                      preview={false}
                      width={20}
                      height={20}
                      src={epicImage}
                      alt='epic-games-launcher'
                    />
                  </div>
                  <Text className='text-xs'>{userPlatformInformation.epic}</Text>
                </Descriptions.Item>
              )}
              {userPlatformInformation.riot.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <div className='mr-[7px]'>
                    <Image
                      preview={false}
                      width={20}
                      height={20}
                      src={riotGames}
                      alt='riot-games-launcher'
                    />
                  </div>
                  <Text className='text-xs'>{userPlatformInformation.riot}</Text>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        </Col>
      ) : (
        'Error'
      )}
      {accessoryInformationLoading ? (
        <Col span={24}>
          <SpinWrapper />
        </Col>
      ) : accessoryInformationSuccess ? (
        <Col span={24} className='mb-24'>
          <Card
            extra={
              isOwner && (
                <Button
                  onClick={() => modalHandler('ac')}
                  icon={<SettingTwoTone twoToneColor={focusedPrimaryColor} />}
                  type='link'
                />
              )
            }
            bordered={false}
            title={
              <h6 className='font-semibold m-0'>
                <FormattedMessage id='accessories' />
              </h6>
            }
            className={`${styles['antdCard']} card-profile-information header-solid h-full`}
            styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
          >
            <Descriptions>
              {!PropChecker(userAccessoryInformation, 'userId') && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <EmptyWrapper imgStyle={{ height: '70px' }} />
                </Descriptions.Item>
              )}
              {userAccessoryInformation.keyboard.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faKeyboard}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userAccessoryInformation.keyboard}</Text>
                </Descriptions.Item>
              )}
              {userAccessoryInformation.mouse.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faMouse}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userAccessoryInformation.mouse}</Text>
                </Descriptions.Item>
              )}
              {userAccessoryInformation.headset.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faHeadphones}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userAccessoryInformation.headset}</Text>
                </Descriptions.Item>
              )}
              {userAccessoryInformation.camera.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faCamera}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userAccessoryInformation.camera}</Text>
                </Descriptions.Item>
              )}
              {userAccessoryInformation.microphone.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faMicrophone}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userAccessoryInformation.microphone}</Text>
                </Descriptions.Item>
              )}
              {userAccessoryInformation.chair.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faChair}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userAccessoryInformation.chair}</Text>
                </Descriptions.Item>
              )}
              {userAccessoryInformation.monitor.length > 0 && (
                <Descriptions.Item className={styles['descriptionLabel']} span={3}>
                  <FontAwesomeIcon
                    className={layoutStyles['fontAwesomeIcon']}
                    icon={faDesktop}
                    style={{ color: '#0f7c10', marginRight: '.5rem' }}
                  />
                  <Text className='text-xs'>{userAccessoryInformation.monitor}</Text>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        </Col>
      ) : (
        'Error'
      )}
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'systemProperties' })}
        onCancel={() => modalHandler('sp')}
        open={systemPropertiesModal}
        destroyOnClose={true}
        centered={true}
        footer={null}
      >
        <SystemPropertiesForm
          modalHandler={() => modalHandler('sp')}
          systemProperties={userSystemInformation}
        />
      </Modal>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'platforms' })}
        onCancel={() => modalHandler('pl')}
        open={platformsModal}
        destroyOnClose={true}
        centered={true}
        footer={null}
      >
        <PlatformForm
          modalHandler={() => modalHandler('pl')}
          platformProperties={userPlatformInformation}
        />
      </Modal>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'accessories' })}
        onCancel={() => modalHandler('ac')}
        open={accessoryModal}
        destroyOnClose={true}
        centered={true}
        footer={null}
      >
        <AccessoryForm
          modalHandler={() => modalHandler('ac')}
          accessoryProperties={userAccessoryInformation}
        />
      </Modal>
    </Row>
  );
};

export default EquipmentInformation;
