import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrganizationsDetail } from '../requests/OrganizationManager';
import { getLoggedInUserInformation } from '../requests/UserInitiatorManager';
import { useGetUserOrganizations } from '../hooks/queries/OrganizationQueries';
import {
  useChatChannels,
  useDeleteUserDevice,
  useGetUnreadChatChannels,
} from '../hooks/queries/UserQueries';
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [activeChannel, setActiveChannel] = useState(null);
  const [activeOtherUser, setActiveOtherUser] = useState(null);
  const [userOwnedPages, setUserOwnedPages] = useState([]);
  const [isManager, setIsManager] = useState(false);

  const { data: chatChannels = [] } = useChatChannels(user);
  const { data: userOrganizationIds } = useGetUserOrganizations(user?.id);
  const { data: hasUnreadMessages = [] } = useGetUnreadChatChannels(user);
  const { mutateAsync: deleteUserDevice } = useDeleteUserDevice();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (hasUnreadMessages.length > 0) {
      queryClient.invalidateQueries({
        queryKey: ['getChatChannels'],
      });
    }
  }, [hasUnreadMessages]);

  useEffect(() => {
    if (userOrganizationIds?.length > 0) {
      getOrganizationsDetail(userOrganizationIds).then((detailResponse) => {
        if (detailResponse.organizations.length > 0) {
          setUserOwnedPages(detailResponse.organizations);
        } else {
          setUserOwnedPages([]);
        }
      });
    } else {
      if (!user && localStorage.getItem('access_token')) {
        checkUserInformation();
      }
    }
  }, [user, userOrganizationIds]);

  const checkUserInformation = () => {
    getLoggedInUserInformation()
      .then((response) => {
        setUser(response?.data?.data);
        localStorage.setItem('language', response?.data?.data?.language);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          localStorage.removeItem('access_token');
          localStorage.removeItem('device_token');
          localStorage.removeItem('language');
        }
      });
  };

  const login = async (response) => {
    if (response.data.manager) {
      setIsManager(true);
    }

    if (response.data) {
      localStorage.setItem('access_token', response.data.token);
      setUser(response?.data);
      localStorage.setItem('language', response?.data?.language);
      navigate('/feed');
    }
  };

  const logout = () => {
    setIsManager(false);
    setUser(null);
    localStorage.removeItem('language');
    const device_token = localStorage.getItem('device_token');
    localStorage.removeItem('access_token');
    if (device_token) {
      deleteUserDevice(device_token).then((response) => {
        navigate('/');
      });
    }
    localStorage.removeItem('device_token');
  };

  const openChat = (channel) => {
    setChatOpen(true);
    setActiveChannel(channel);
    let otherUser = channel?.user?.filter((x) => x.id != user?.id);
    if (otherUser && otherUser.length > 0) {
      setActiveOtherUser(otherUser[0]);
    }
  };

  const closeChat = () => {
    setChatOpen(false);
    setActiveChannel(null);
    setActiveOtherUser(null);
  };

  const openFromProfile = (channel) => {
    if (channel) {
      openChat(channel);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        setUser,
        isManager,
        userOwnedPages,
        chatOpen,
        activeChannel,
        activeOtherUser,
        chatChannels,
        openChat,
        closeChat,
        openFromProfile,
        hasUnreadMessages,
        checkUserInformation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
