import styles from '../../../style/chat.module.scss';
import { Col, Row, Typography, Image } from 'antd';
import { AuthContext } from '../../../providers/AuthProvider';
import { useContext, useEffect, useState } from 'react';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';
import { getRelativeTime } from '../../../helpers/utils/TimeFormatters';
import { truncateText } from '../../../helpers/constants/constantHelpers';
import { useIntl } from 'react-intl';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const { Paragraph } = Typography;

const partialMessage = ({ onClick, channel, unread }) => {
  const { user } = useContext(AuthContext);
  const [messagingUser, setMessagingUser] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    let otherUser = channel?.user?.filter((x) => x.id !== user?.id);
    if (otherUser && otherUser.length > 0) {
      setMessagingUser(otherUser[0]);

      if (otherUser[0].userPhotos && otherUser[0].userPhotos.length > 0) {
        setProfilePhoto(otherUser[0].userPhotos[0].profile_photo);
      }
    }

    if (channel?.chatmessages?.contentMessage && channel?.chatmessages?.contentMessage !== '') {
      setContent(JSON.parse(channel?.chatmessages?.contentMessage).content);
    }
  }, [user, channel]);

  const translator = useIntl();
  const sanitizedHtml = parse(
    DOMPurify.sanitize(
      truncateText(
        content?.includes('<div data-youtube-video="">')
          ? translator.formatMessage({ id: 'userSentVideo' })
          : content?.includes('<img')
          ? translator.formatMessage({ id: 'userSentGif' })
          : content,
        20,
      ),
    ),
  );

  return (
    <Row onClick={onClick} className={styles['partialMessage']}>
      <Col span={6} className={styles['profileImg']}>
        <Image
          className='rounded-[8px] object-cover'
          width={50}
          preview={false}
          src={profilePhoto ? profilePhoto : defaultProfilePhoto}
        />
      </Col>
      <Col
        span={18}
        className='relative pb-2 cursor-pointer border-b border-t-0 border-l-0 border-r-0 border-solid border-white-gray'
      >
        <span className='absolute flex items-center gap-1 top-[3px] right-0 text-[0.6rem]'>
          {getRelativeTime(channel?.chatmessages?.updated_at)}
          {unread && <div className='w-[10px] h-[10px] bg-secondary rounded-full'></div>}
        </span>
        <strong className='text-[0.85rem]'>{messagingUser?.username}</strong>
        <div className='max-w-[195px] truncate'>
          <Paragraph className='[&>p]:mb-0 !mb-0'>{sanitizedHtml}</Paragraph>
        </div>
      </Col>
    </Row>
  );
};

export default partialMessage;
