// @ts-ignore
import React, { useState, createRef, useContext, useEffect } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import FileUploader from '../../helpers/utils/FileUploader';
import 'cropperjs/dist/cropper.css';
import '../../style/cropper.css';
import { Button, Flex, Image, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from '../../providers/AuthProvider';
import { UtilityContext } from '../../providers/UtilityProvider';

const { Text } = Typography;

export const CropperComponent: React.FC = ({ returnedData, label, isLoading, formName=null, required=false, forOrganization=false}) => {
  // @ts-ignore
  const { user } = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const cropperRef = createRef<ReactCropperElement>();
  const { openNotification } = useContext(UtilityContext);

  const fileUploadHandler = (file) => {
    if (file.contentType === 'image/gif') {
      if (file.size / 1024 / 1024 > 3) {
        openNotification('badGifSize', 'error');
        setSaveButtonDisable(true);
        setImage(null);
        return false;
      }
      let returnedObj = {
        base64: file.base64.split('base64,')[1],
        key: file['key'].replace(/\s/g, '_').toLocaleLowerCase(),
        contentType: file['contentType'],
        size: file['size'],
      };
      returnedData(returnedObj);
    } else if (file.size > 0) {
      setImage(file);
      setSaveButtonDisable(false);
    }
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      let returnedObj = {
        base64: cropperRef.current?.cropper?.getCroppedCanvas()?.toDataURL().split('base64,')[1],
        key: image['key'].replace(/\s/g, '_').toLocaleLowerCase(),
        contentType: image['contentType'],
        size: image['size'],
      };
      returnedData(returnedObj);
    }
  };

  if (label === 'profilePhoto' || label === 'addTeamLogo') {
    return (
      <>
        <div className='w-full'>
          <div>
            <FileUploader
              formName={formName}
              accept={window.config.acceptedImageFiles}
              placeholderId={null}
              multiple={false}
              split={false}
              bucketCallBack={(file) => fileUploadHandler(file)}
              placeholderInline={label}
              required={required}
            />
            {image && !forOrganization && (
              <Flex className='w-full'>
                <Flex className='mb-3 w-full' gap='0.5rem' align='center'>
                  <div className='box'>
                    <div
                      className='img-preview rounded-[8px] object-cover'
                      style={{ width: '55px', height: '45px' }}
                    />
                  </div>
                  <div>
                    <Text className='block'>{user?.username}</Text>
                    <Text className='text-xs'>
                      {user?.first_name} {user?.last_name}
                    </Text>
                  </div>
                </Flex>
              </Flex>
            )}
          </div>
          {image && (
            <Cropper
              ref={cropperRef}
              style={{ height: 200, width: '100%' }}
              zoomTo={0}
              aspectRatio={1.25}
              preview='.img-preview'
              src={image?.base64}
              viewMode={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
              className='mb-5'
              cropend={forOrganization ? getCropData : null}
              ready={forOrganization ? getCropData : null}
            />
          )}
        </div>
        {!forOrganization && (
          <Button
            loading={isLoading}
            className='mt-4'
            type='primary'
            onClick={getCropData}
            disabled={saveButtonDisable}
          >
            <Text>
              <FormattedMessage id='save' />
            </Text>
          </Button>
        )}
      </>
    );
  } else {
    return (
      <>
        <div className='w-full'>
          <div className='relative'>
            <FileUploader
              formName={formName}
              accept={window.config.acceptedImageFiles}
              placeholderId={null}
              multiple={false}
              split={false}
              bucketCallBack={(file) => fileUploadHandler(file)}
              placeholderInline={label}
              required={required}
            />
            {!forOrganization && (
              <Text>
                <FormattedMessage id='bannerHeightWidth'/>
              </Text>
            )}
          </div>
          {image && (
            <Cropper
              ref={cropperRef}
              style={{ height: 200, width: '100%' }}
              zoomTo={0}
              aspectRatio={4.6}
              preview='.img-preview'
              src={image?.base64}
              viewMode={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
              cropend={forOrganization ? getCropData : null}
              ready={forOrganization ? getCropData : null}
            />
          )}
        </div>
        {!forOrganization && (
          <Button loading={isLoading} className='mt-4' type='primary' onClick={getCropData}>
            <Text className='text-inherit'>
              <FormattedMessage id='save' />
            </Text>
          </Button>
        )}
      </>
    );
  }
};

export default CropperComponent;
