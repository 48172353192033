import { createContext, useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import axiosInstance from '../lib/service/axios';
import { useGetOrganizationCategories } from '../hooks/queries/OrganizationQueries';
import { AuthContext } from './AuthProvider';

export const UtilityContext = createContext({});

const UtilityProvider = ({ children }) => {
  const translator = useIntl();
  const { user } = useContext(AuthContext);
  const [isUserKey, setIsUserKey] = useState();
  const [api, contextHolder] = notification.useNotification();
  const { data: organizationCategories } = useGetOrganizationCategories(user);
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem('theme')));

  const changeThemeHandler = () => {
    setTheme(!theme);
  };

  useEffect(() => {
    if (theme === true) {
      document.getElementsByTagName('html')[0].classList.add('dark');
    } else if (theme === false) {
      document.getElementsByTagName('html')[0].classList.remove('dark');
    }
    localStorage.setItem('theme', JSON.stringify(theme));
  }, [theme]);

  const openNotification = (key, type) => {
    api[type]({
      description: translator.formatMessage({ id: key }),
    });
  };

  const dynamicAxiosInstance = async (actionName) => {
    let formObject = {
      action: actionName,
    };
    return await axiosInstance.post('securedHandle', formObject);
  };

  return (
    <UtilityContext.Provider
      value={{
        openNotification,
        dynamicAxiosInstance,
        organizationCategories,
        changeThemeHandler,
        theme,
        setIsUserKey,
        isUserKey,
      }}
    >
      {contextHolder}
      {children}
    </UtilityContext.Provider>
  );
};
export default UtilityProvider;
